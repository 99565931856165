import { Pipe, PipeTransform } from '@angular/core';
import { ItemOption } from '../models/survey-items/question-items/itemOption';
import * as _ from 'lodash';

// TODO: ensure  that we modify options array reference when we apply transform because pipe is pure
@Pipe({ name: 'pureOptionsOnly', pure: true })
export class PureItemOptionsOnly implements PipeTransform {
    transform(options: ItemOption[]): ItemOption[] {
        return _.filter(
            options,
            (item: ItemOption): boolean => {
                return !item.is_other && !item.is_none_of_above;
            }
        );
    }
}
