<mat-form-field class="no-label no-hint no-top" appearance="outline" floatLabel="never">
    <mat-label>{{placeholder}}</mat-label>
    <input
    aria-label="datepicker"
    matInput
    [matDatepicker]="picker"
    [(ngModel)]="dateTime"
    (ngModelChange)="propagateChange($event)"
    [min]="minDate"
    [max]="maxDate"
    (click)="openDatePicker()"
    #pickerRef
    />
    <mat-datepicker-toggle matSuffix [for]="picker" >
      <mat-icon matDatepickerToggleIcon svgIcon="fi_calendar"></mat-icon>
   </mat-datepicker-toggle>
<!--    <mat-datepicker-->
<!--    #picker-->
<!--    [showSeconds]="false"-->
<!--       [stepHour]="1"-->
<!--    [stepMinute]="1"-->
<!--       [touchUi]="false" -->
<!--    [enableMeridian]="false"-->
<!--       [disableMinute]="false" -->
<!--    [hideTime]="datepickerMode===dateOnlyMode"-->
<!--       (closed)="afterPickerClosed()" (opened)="beforePickerOpen()">-->
<!--    </mat-datepicker>-->
<!--    Todo migration: Test this component-->
    <mat-datepicker
        #picker
        [touchUi]="false"
        (closed)="afterPickerClosed()" (opened)="beforePickerOpen()">
    </mat-datepicker>
 </mat-form-field>
