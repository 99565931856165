var t = {
    getItemSync: function (t) {
      try {
        return localStorage.getItem(t) || null;
      } catch (t) {
        return null;
      }
    },
    getItem: function (t, e) {
      var n = this;
      return new Promise(function (i, a) {
        try {
          var o = n.getItemSync(t);
          null == e || e(null, o), i(o);
        } catch (t) {
          e && e(t, null), a(t);
        }
      });
    },
    setItem: function (t, e, n) {
      return new Promise(function (i, a) {
        try {
          localStorage.setItem(t, e), n && n(null, e), i(e);
        } catch (t) {
          n && n(t, null), a(t);
        }
      });
    }
  },
  e = function () {
    return e = Object.assign || function (t) {
      for (var e, n = 1, i = arguments.length; n < i; n++) for (var a in e = arguments[n]) Object.prototype.hasOwnProperty.call(e, a) && (t[a] = e[a]);
      return t;
    }, e.apply(this, arguments);
  };
function n(t, e, n, i) {
  return new (n || (n = Promise))(function (a, o) {
    function r(t) {
      try {
        l(i.next(t));
      } catch (t) {
        o(t);
      }
    }
    function s(t) {
      try {
        l(i.throw(t));
      } catch (t) {
        o(t);
      }
    }
    function l(t) {
      var e;
      t.done ? a(t.value) : (e = t.value, e instanceof n ? e : new n(function (t) {
        t(e);
      })).then(r, s);
    }
    l((i = i.apply(t, e || [])).next());
  });
}
function i(t, e) {
  var n,
    i,
    a,
    o,
    r = {
      label: 0,
      sent: function () {
        if (1 & a[0]) throw a[1];
        return a[1];
      },
      trys: [],
      ops: []
    };
  return o = {
    next: s(0),
    throw: s(1),
    return: s(2)
  }, "function" == typeof Symbol && (o[Symbol.iterator] = function () {
    return this;
  }), o;
  function s(o) {
    return function (s) {
      return function (o) {
        if (n) throw new TypeError("Generator is already executing.");
        for (; r;) try {
          if (n = 1, i && (a = 2 & o[0] ? i.return : o[0] ? i.throw || ((a = i.return) && a.call(i), 0) : i.next) && !(a = a.call(i, o[1])).done) return a;
          switch (i = 0, a && (o = [2 & o[0], a.value]), o[0]) {
            case 0:
            case 1:
              a = o;
              break;
            case 4:
              return r.label++, {
                value: o[1],
                done: !1
              };
            case 5:
              r.label++, i = o[1], o = [0];
              continue;
            case 7:
              o = r.ops.pop(), r.trys.pop();
              continue;
            default:
              if (!(a = r.trys, (a = a.length > 0 && a[a.length - 1]) || 6 !== o[0] && 2 !== o[0])) {
                r = 0;
                continue;
              }
              if (3 === o[0] && (!a || o[1] > a[0] && o[1] < a[3])) {
                r.label = o[1];
                break;
              }
              if (6 === o[0] && r.label < a[1]) {
                r.label = a[1], a = o;
                break;
              }
              if (a && r.label < a[2]) {
                r.label = a[2], r.ops.push(o);
                break;
              }
              a[2] && r.ops.pop(), r.trys.pop();
              continue;
          }
          o = e.call(t, r);
        } catch (t) {
          o = [6, t], i = 0;
        } finally {
          n = a = 0;
        }
        if (5 & o[0]) throw o[1];
        return {
          value: o[0] ? o[1] : void 0,
          done: !0
        };
      }([o, s]);
    };
  }
}
function a(t, e, n) {
  if (n || 2 === arguments.length) for (var i, a = 0, o = e.length; a < o; a++) !i && a in e || (i || (i = Array.prototype.slice.call(e, 0, a)), i[a] = e[a]);
  return t.concat(i || Array.prototype.slice.call(e));
}
var o = function t(e, n) {
  if (e === n) return !0;
  if (e && n && "object" == typeof e && "object" == typeof n) {
    if (e.constructor !== n.constructor) return !1;
    var i, a, o;
    if (Array.isArray(e)) {
      if ((i = e.length) != n.length) return !1;
      for (a = i; 0 != a--;) if (!t(e[a], n[a])) return !1;
      return !0;
    }
    if (e.constructor === RegExp) return e.source === n.source && e.flags === n.flags;
    if (e.valueOf !== Object.prototype.valueOf) return e.valueOf() === n.valueOf();
    if (e.toString !== Object.prototype.toString) return e.toString() === n.toString();
    if ((i = (o = Object.keys(e)).length) !== Object.keys(n).length) return !1;
    for (a = i; 0 != a--;) if (!Object.prototype.hasOwnProperty.call(n, o[a])) return !1;
    for (a = i; 0 != a--;) {
      var r = o[a];
      if (!t(e[r], n[r])) return !1;
    }
    return !0;
  }
  return e != e && n != n;
};
function r(t, e) {
  var n = Object.keys(e || {}).filter(function (n) {
    var i = null == t ? void 0 : t[n],
      a = null == e ? void 0 : e[n];
    return !o(i, a);
  });
  return Object.keys(t || {}).filter(function (t) {
    Object.keys(e || {}).includes(t) || n.push(t);
  }), Object.keys(n).length ? n : null;
}
var s, l;
function u(t, e, n) {
  var i = "shortString",
    a = !0;
  "number" == typeof n && (i = "javaDouble", a = !1), t[i] = t[i] || {}, t[i][e] = a ? n + "" : n;
}
function c(t) {
  return !!t && "object" == typeof t && void 0 !== t.value;
}
function h(t) {
  return Object.fromEntries(Object.entries(t).map(function (t) {
    var e = t[0],
      n = t[1];
    return [e, c(n) ? n : {
      value: n
    }];
  }));
}
function v(t) {
  return e(e({}, t), {
    identity: t.identity ? e(e({}, t.identity), {
      traits: h(t.identity.traits || {})
    }) : void 0
  });
}
!function (t) {
  t.NONE = "NONE", t.DEFAULT_FLAGS = "DEFAULT_FLAGS", t.CACHE = "CACHE", t.SERVER = "SERVER";
}(s || (s = {}));
var d,
  g = null,
  f = "FLAGSMITH_EVENT",
  p = "https://edge.api.flagsmith.com/api/v1/",
  y = function () {
    function t(t) {
      var n = this;
      this._trigger = null, this._triggerLoadingState = null, this.timestamp = null, this.isLoading = !1, this.eventSource = null, this.getFlags = function () {
        var t = n,
          i = t.api,
          a = t.evaluationContext;
        n.log("Get Flags"), n.isLoading = !0, n.loadingState.isFetching || n.setLoadingState(e(e({}, n.loadingState), {
          isFetching: !0
        }));
        var o = "".concat(n.getContext().identity),
          l = function (t) {
            var i, a, l, c;
            if (t && o === "".concat(n.getContext().identity)) {
              var h = t.flags,
                v = t.traits,
                d = t.identifier;
              n.isLoading = !1;
              var g = {},
                f = {};
              v = v || [], (h = h || []).forEach(function (t) {
                g[t.feature.name.toLowerCase().replace(/ /g, "_")] = {
                  id: t.feature.id,
                  enabled: t.enabled,
                  value: t.feature_state_value
                };
              }), v.forEach(function (t) {
                f[t.trait_key.toLowerCase().replace(/ /g, "_")] = {
                  transient: t.transient,
                  value: t.trait_value
                };
              }), n.oldFlags = e({}, n.flags);
              var p = r(n.oldFlags, g),
                y = r(null === (i = n.evaluationContext.identity) || void 0 === i ? void 0 : i.traits, f);
              if ((d || Object.keys(f).length) && (n.evaluationContext.identity = e(e({}, n.evaluationContext.identity), {
                traits: f
              }), d && (n.evaluationContext.identity.identifier = d)), n.flags = g, n.updateStorage(), n._onChange(n.oldFlags, {
                isFromServer: !0,
                flagsChanged: p,
                traitsChanged: y
              }, n._loadedState(null, s.SERVER)), n.datadogRum) try {
                if (n.datadogRum.trackTraits) {
                  var m = {};
                  Object.keys((null === (a = n.evaluationContext.identity) || void 0 === a ? void 0 : a.traits) || {}).map(function (t) {
                    m["flagsmith_trait_" + t] = n.getTrait(t);
                  });
                  var S = e(e(e({}, n.datadogRum.client.getUser()), {
                    id: n.datadogRum.client.getUser().id || (null === (l = n.evaluationContext.identity) || void 0 === l ? void 0 : l.identifier)
                  }), m);
                  n.log("Setting Datadog user", S), n.datadogRum.client.setUser(S);
                }
              } catch (t) {
                console.error(t);
              }
              if (n.dtrum) try {
                var C = {
                  javaDouble: {},
                  date: {},
                  shortString: {},
                  javaLongOrObject: {}
                };
                Object.keys(n.flags).map(function (t) {
                  u(C, "flagsmith_value_" + t, n.getValue(t, {
                    skipAnalytics: !0
                  })), u(C, "flagsmith_enabled_" + t, n.hasFeature(t, {
                    skipAnalytics: !0
                  }));
                }), Object.keys((null === (c = n.evaluationContext.identity) || void 0 === c ? void 0 : c.traits) || {}).map(function (t) {
                  u(C, "flagsmith_trait_" + t, n.getTrait(t));
                }), n.log("Sending javaLongOrObject traits to dynatrace", C.javaLongOrObject), n.log("Sending date traits to dynatrace", C.date), n.log("Sending shortString traits to dynatrace", C.shortString), n.log("Sending javaDouble to dynatrace", C.javaDouble), n.dtrum.sendSessionProperties(C.javaLongOrObject, C.date, C.shortString, C.javaDouble);
              } catch (t) {
                console.error(t);
              }
            }
          };
        return a.identity ? Promise.all([a.identity.traits && Object.keys(a.identity.traits).length || !a.identity.identifier ? n.getJSON(i + "identities/", "POST", JSON.stringify({
          identifier: a.identity.identifier,
          transient: a.identity.transient,
          traits: Object.entries(a.identity.traits).map(function (t) {
            var e = t[0],
              n = t[1];
            return {
              trait_key: e,
              trait_value: null == n ? void 0 : n.value,
              transient: null == n ? void 0 : n.transient
            };
          }).filter(function (t) {
            return void 0 !== t.trait_value || (n.log("Warning - attempted to set an undefined trait value for key", t.trait_key), !1);
          })
        })) : n.getJSON(i + "identities/?identifier=" + encodeURIComponent(a.identity.identifier) + (a.identity.transient ? "&transient=true" : ""))]).then(function (t) {
          return n.evaluationContext.identity = e(e({}, n.evaluationContext.identity), {
            traits: {}
          }), l(null == t ? void 0 : t[0]);
        }).catch(function (t) {
          var e = t.message,
            n = new Error(e);
          return Promise.reject(n);
        }) : n.getJSON(i + "flags/").then(function (t) {
          return l({
            flags: t,
            traits: void 0
          });
        });
      }, this.analyticsFlags = function () {
        var t = n.api;
        if (n.evaluationEvent && n.evaluationContext.environment && n.evaluationEvent[n.evaluationContext.environment.apiKey]) return n.evaluationEvent && 0 !== Object.getOwnPropertyNames(n.evaluationEvent).length && 0 !== Object.getOwnPropertyNames(n.evaluationEvent[n.evaluationContext.environment.apiKey]).length ? n.getJSON(t + "analytics/flags/", "POST", JSON.stringify(n.evaluationEvent[n.evaluationContext.environment.apiKey])).then(function (t) {
          if (n.evaluationContext.environment) {
            var i = n.getState();
            n.evaluationEvent || (n.evaluationEvent = {}), n.evaluationEvent[n.evaluationContext.environment.apiKey] = {}, n.setState(e(e({}, i), {
              evaluationEvent: n.evaluationEvent
            })), n.updateEventStorage();
          }
        }).catch(function (t) {
          n.log("Exception fetching evaluationEvent", t);
        }) : void 0;
      }, this.datadogRum = null, this.loadingState = {
        isLoading: !0,
        isFetching: !0,
        error: null,
        source: s.NONE
      }, this.canUseStorage = !1, this.analyticsInterval = null, this.api = null, this.cacheFlags = !1, this.ts = null, this.enableAnalytics = !1, this.enableLogs = !1, this.evaluationContext = {}, this.evaluationEvent = null, this.flags = null, this.getFlagInterval = null, this.headers = null, this.initialised = !1, this.oldFlags = null, this.onChange = null, this.onError = null, this.ticks = null, this.timer = null, this.dtrum = null, this.withTraits = null, this.cacheOptions = {
        ttl: 0,
        skipAPI: !1,
        loadStale: !1,
        storageKey: void 0
      }, this.getValue = function (t, e, i) {
        var a = n.flags && n.flags[t.toLowerCase().replace(/ /g, "_")],
          o = null;
        if (a && (o = a.value), (null == e ? void 0 : e.skipAnalytics) || i || n.evaluateFlag(t, "VALUE"), null === o && void 0 !== (null == e ? void 0 : e.fallback)) return e.fallback;
        if (null == e ? void 0 : e.json) try {
          return null === o ? (n.log("Tried to parse null flag as JSON: " + t), null) : JSON.parse(o);
        } catch (t) {
          return e.fallback;
        }
        return o;
      }, this.getTrait = function (t) {
        var e, i;
        return (null === (e = n.evaluationContext.identity) || void 0 === e ? void 0 : e.traits) && (null === (i = n.evaluationContext.identity.traits[t.toLowerCase().replace(/ /g, "_")]) || void 0 === i ? void 0 : i.value);
      }, this.getAllTraits = function () {
        var t;
        return Object.fromEntries(Object.entries((null === (t = n.evaluationContext.identity) || void 0 === t ? void 0 : t.traits) || {}).map(function (t) {
          var e = t[0],
            n = t[1];
          return [e, null == n ? void 0 : n.value];
        }));
      }, this.setContext = function (t) {
        var i = v(t);
        return n.evaluationContext = e(e({}, i), {
          environment: i.environment || n.evaluationContext.environment
        }), n.initialised ? n.getFlags() : Promise.resolve();
      }, this.getContext = function () {
        return n.evaluationContext;
      }, this.updateContext = function (t) {
        return n.setContext(e(e({}, n.getContext()), t));
      }, this.setTrait = function (t, i) {
        var a;
        if (n.api) return n.setContext(e(e({}, n.evaluationContext), {
          identity: e(e({}, n.evaluationContext.identity), {
            traits: e(e({}, null === (a = n.evaluationContext.identity) || void 0 === a ? void 0 : a.traits), h(Object.fromEntries([[t, i]])))
          })
        }));
      }, this.setTraits = function (t) {
        var i;
        if (n.api) return n.setContext(e(e({}, n.evaluationContext), {
          identity: e(e({}, n.evaluationContext.identity), {
            traits: e(e({}, null === (i = n.evaluationContext.identity) || void 0 === i ? void 0 : i.traits), Object.fromEntries(Object.entries(t).map(function (t) {
              var e = t[0],
                n = t[1];
              return [e, c(n) ? n : {
                value: n
              }];
            })))
          })
        }));
        console.error("Attempted to " + "setTraits" + " a user before calling flagsmith.init. Call flagsmith.init first, if you wish to prevent it sending a request for flags, call init with preventFetch:true.");
      }, this.hasFeature = function (t, e) {
        var i = "object" == typeof e,
          a = n.flags && n.flags[t.toLowerCase().replace(/ /g, "_")],
          o = !1;
        return !a && i && void 0 !== e.fallback ? o = null == e ? void 0 : e.fallback : a && a.enabled && (o = !0), (i && !e.skipAnalytics || !e) && n.evaluateFlag(t, "ENABLED"), o;
      }, this.getStorageKey = function () {
        var t, e;
        return (null === (t = n.cacheOptions) || void 0 === t ? void 0 : t.storageKey) || "FLAGSMITH_DB_" + (null === (e = n.evaluationContext.environment) || void 0 === e ? void 0 : e.apiKey);
      }, this.getJSON = function (t, e, i) {
        var a,
          o = n;
        o.evaluationContext;
        var r = o.headers,
          s = {
            method: e || "GET",
            body: i,
            cache: "no-cache",
            headers: {}
          };
        n.evaluationContext.environment && (s.headers["X-Environment-Key"] = n.evaluationContext.environment.apiKey), e && "GET" !== e && (s.headers["Content-Type"] = "application/json; charset=utf-8"), r && Object.assign(s.headers, r), l || console.error("Flagsmith: fetch is undefined, please specify a fetch implementation into flagsmith.init to support SSR.");
        var u = "".concat(null === (a = n.evaluationContext.identity) || void 0 === a ? void 0 : a.identifier);
        return l(t, s).then(function (i) {
          var a,
            o,
            r = "".concat(null === (a = n.evaluationContext.identity) || void 0 === a ? void 0 : a.identifier);
          if (u === r) {
            var s = null === (o = i.headers) || void 0 === o ? void 0 : o.get("x-flagsmith-document-updated-at");
            if (s) try {
              var l = parseFloat(s);
              if (isNaN(l)) return Promise.reject("Failed to parse x-flagsmith-document-updated-at");
              n.timestamp = l;
            } catch (t) {
              n.log(t, "Failed to parse x-flagsmith-document-updated-at", s);
            }
            return n.log("Fetch response: " + i.status + " " + (e || "GET") + 0 + t), i.text().then(function (t) {
              var e = t;
              try {
                e = JSON.parse(t);
              } catch (t) {}
              return !e && i.status && (e = "API Response: ".concat(i.status)), i.status && i.status >= 200 && i.status < 300 ? e : Promise.reject(new Error(e));
            });
          }
          n.log("Received response with identity mismatch, ignoring response. Requested: ".concat(u, ", Current: ").concat(r));
        });
      }, this.evaluateFlag = function (t, e) {
        if (n.datadogRum && (n.datadogRum.client.addFeatureFlagEvaluation ? "VALUE" === e ? n.datadogRum.client.addFeatureFlagEvaluation("flagsmith_value_" + t, n.getValue(t, {}, !0)) : n.datadogRum.client.addFeatureFlagEvaluation("flagsmith_enabled_" + t, n.hasFeature(t, !0)) : console.error("Flagsmith: Your datadog RUM client does not support the function addFeatureFlagEvaluation, please update it.")), n.enableAnalytics) {
          if (!n.evaluationEvent || !n.evaluationContext.environment) return;
          n.evaluationEvent[n.evaluationContext.environment.apiKey] || (n.evaluationEvent[n.evaluationContext.environment.apiKey] = {}), void 0 === n.evaluationEvent[n.evaluationContext.environment.apiKey][t] && (n.evaluationEvent[n.evaluationContext.environment.apiKey][t] = 0), n.evaluationEvent[n.evaluationContext.environment.apiKey][t] += 1;
        }
        n.updateEventStorage();
      }, this._onChange = function (t, e, i) {
        var a, o;
        n.setLoadingState(i), null === (a = n.onChange) || void 0 === a || a.call(n, t, e, n.loadingState), null === (o = n._trigger) || void 0 === o || o.call(n);
      }, l = t.fetch ? t.fetch : "undefined" != typeof fetch ? fetch : null === global || void 0 === global ? void 0 : global.fetch, this.canUseStorage = "undefined" != typeof window || !!t.browserlessStorage, this.log("Constructing flagsmith instance " + t), t.eventSource && (d = t.eventSource), t.AsyncStorage && (g = t.AsyncStorage);
    }
    return t.prototype.init = function (t) {
      var a, o, u;
      return n(this, void 0, void 0, function () {
        var c,
          h,
          d,
          y,
          m,
          S,
          C,
          _,
          b,
          E,
          x,
          O,
          F,
          w,
          L,
          I,
          j,
          A,
          k,
          T,
          P,
          N,
          R,
          K,
          D,
          U,
          G,
          J,
          H,
          M,
          V,
          q,
          B = this;
        return i(this, function (W) {
          switch (W.label) {
            case 0:
              c = v(t.evaluationContext || this.evaluationContext), W.label = 1;
            case 1:
              if (W.trys.push([1, 13,, 14]), h = t.environmentID, d = t.api, y = void 0 === d ? p : d, m = t.headers, S = t.onChange, C = t.cacheFlags, _ = t.datadogRum, b = t.onError, E = t.defaultFlags, x = t.fetch, O = t.preventFetch, F = t.enableLogs, w = t.enableDynatrace, L = t.enableAnalytics, I = t.realtime, j = t.eventSourceUrl, A = void 0 === j ? "https://realtime.flagsmith.com/" : j, k = t.AsyncStorage, T = t.identity, P = t.traits, N = t.state, R = t.cacheOptions, K = t.angularHttpClient, D = t._trigger, U = t._triggerLoadingState, c.environment = h ? {
                apiKey: h
              } : c.environment, !c.environment || !c.environment.apiKey) throw new Error("Please provide `evaluationContext.environment` with non-empty `apiKey`");
              if (c.identity = T || P ? {
                identifier: T,
                traits: P ? Object.fromEntries(Object.entries(P).map(function (t) {
                  return [t[0], {
                    value: t[1]
                  }];
                })) : {}
              } : c.identity, this.evaluationContext = c, this.api = (X = y).endsWith("/") ? X : X + "/", this.headers = m, this.getFlagInterval = null, this.analyticsInterval = null, this.onChange = S, G = "Wrong Flagsmith Configuration: preventFetch is true and no defaulFlags provided", this._trigger = D || this._trigger, this._triggerLoadingState = U || this._triggerLoadingState, this.onError = function (t) {
                B.setLoadingState(e(e({}, B.loadingState), {
                  isFetching: !1,
                  isLoading: !1,
                  error: t
                })), null == b || b(t);
              }, this.enableLogs = F || !1, this.cacheOptions = R ? {
                skipAPI: !!R.skipAPI,
                ttl: R.ttl || 0,
                storageKey: R.storageKey,
                loadStale: !!R.loadStale
              } : this.cacheOptions, !this.cacheOptions.ttl && this.cacheOptions.skipAPI && console.warn("Flagsmith: you have set a cache ttl of 0 and are skipping API calls, this means the API will not be hit unless you clear local storage."), x && (l = x), this.enableAnalytics = L || !1, this.flags = Object.assign({}, E) || {}, this.datadogRum = _ || null, this.initialised = !0, this.ticks = 1e4, this.timer = this.enableLogs ? new Date().valueOf() : null, this.cacheFlags = void 0 !== g && !!C, f = "FLAGSMITH_EVENT_" + c.environment.apiKey, k && (g = k), I && "undefined" != typeof window && this.setupRealtime(A, c.environment.apiKey), Object.keys(this.flags).length && (this.loadingState = e(e({}, this.loadingState), {
                isLoading: !1,
                source: s.DEFAULT_FLAGS
              })), this.setState(N), this.log("Initialising with properties", t, this), w && ("undefined" == typeof dtrum ? console.error("You have attempted to enable dynatrace but dtrum is undefined, please check you have the Dynatrace RUM JavaScript API installed.") : this.dtrum = dtrum), K && (l = function (t) {
                return function (e, n) {
                  var i = n.headers,
                    a = n.method,
                    o = n.body;
                  return new Promise(function (n) {
                    switch (a) {
                      case "GET":
                        return t.get(e, {
                          headers: i
                        }).subscribe(function (t) {
                          n({
                            ok: !0,
                            text: function () {
                              return Promise.resolve(t);
                            }
                          });
                        });
                      case "POST":
                      case "PUT":
                        return t.post(e, o, {
                          headers: i
                        }).subscribe(function (t) {
                          n({
                            ok: !0,
                            text: function () {
                              return Promise.resolve(t);
                            }
                          });
                        });
                    }
                  });
                };
              }(K)), g && this.canUseStorage && g.getItem(f).then(function (t) {
                try {
                  B.evaluationEvent = JSON.parse(t) || {};
                } catch (t) {
                  B.evaluationEvent = {};
                }
                B.analyticsInterval = setInterval(B.analyticsFlags, B.ticks);
              }), this.enableAnalytics && (this.analyticsInterval && clearInterval(this.analyticsInterval), g && this.canUseStorage && g.getItem(f, function (t, n) {
                if (n && B.evaluationContext.environment) {
                  var i = JSON.parse(n);
                  if (i[B.evaluationContext.environment.apiKey]) {
                    var a = B.getState();
                    B.log("Retrieved events from cache", n), B.setState(e(e({}, a), {
                      evaluationEvent: i[B.evaluationContext.environment.apiKey]
                    }));
                  }
                }
              })), !C) return [3, 9];
              if (!g || !this.canUseStorage) return [3, 8];
              J = function (t, a) {
                return n(B, void 0, void 0, function () {
                  var t,
                    n,
                    o,
                    l,
                    u,
                    c,
                    h,
                    d,
                    g,
                    f,
                    p,
                    y,
                    m,
                    S,
                    C,
                    _,
                    b,
                    x,
                    F,
                    w = this;
                  return i(this, function (i) {
                    switch (i.label) {
                      case 0:
                        if (!a) return [3, 7];
                        t = null, n = null, i.label = 1;
                      case 1:
                        return i.trys.push([1, 5,, 6]), o = JSON.parse(a), l = !1, u = !1, o && o.api === this.api && (null === (f = null === (g = o.evaluationContext) || void 0 === g ? void 0 : g.environment) || void 0 === f ? void 0 : f.apiKey) === (null === (p = this.evaluationContext.environment) || void 0 === p ? void 0 : p.apiKey) && (c = !0, this.evaluationContext.identity && (null === (m = null === (y = o.evaluationContext) || void 0 === y ? void 0 : y.identity) || void 0 === m ? void 0 : m.identifier) !== this.evaluationContext.identity.identifier && (this.log("Ignoring cache, identity has changed from " + (null === (C = null === (S = o.evaluationContext) || void 0 === S ? void 0 : S.identity) || void 0 === C ? void 0 : C.identifier) + " to " + this.evaluationContext.identity.identifier), c = !1), this.cacheOptions.ttl && (!o.ts || new Date().valueOf() - o.ts > this.cacheOptions.ttl) && (o.ts && !this.cacheOptions.loadStale ? (this.log("Ignoring cache, timestamp is too old ts:" + o.ts + " ttl: " + this.cacheOptions.ttl + " time elapsed since cache: " + (new Date().valueOf() - o.ts) + "ms"), c = !1) : o.ts && this.cacheOptions.loadStale && (this.log("Loading stale cache, timestamp ts:" + o.ts + " ttl: " + this.cacheOptions.ttl + " time elapsed since cache: " + (new Date().valueOf() - o.ts) + "ms"), u = !0, c = !0)), c && (l = !0, t = r(this.flags, o.flags), this.setState(e(e({}, o), {
                          evaluationContext: v(e(e({}, o.evaluationContext), {
                            identity: (null === (_ = o.evaluationContext) || void 0 === _ ? void 0 : _.identity) ? e(e({}, null === (b = o.evaluationContext) || void 0 === b ? void 0 : b.identity), {
                              traits: e({}, P || {})
                            }) : void 0
                          }))
                        })), this.log("Retrieved flags from cache", o))), l ? (h = !O && (!this.cacheOptions.skipAPI || u), this._onChange(null, {
                          isFromServer: !1,
                          flagsChanged: t,
                          traitsChanged: n
                        }, this._loadedState(null, s.CACHE, h)), this.oldFlags = this.flags, this.cacheOptions.skipAPI && l && !u && this.log("Skipping API, using cache"), h && this.getFlags().catch(function (t) {
                          var e;
                          null === (e = w.onError) || void 0 === e || e.call(w, t);
                        }), [3, 4]) : [3, 2];
                      case 2:
                        return O ? [3, 4] : [4, this.getFlags()];
                      case 3:
                        i.sent(), i.label = 4;
                      case 4:
                        return [3, 6];
                      case 5:
                        return d = i.sent(), this.log("Exception fetching cached logs", d), [3, 6];
                      case 6:
                        return [3, 10];
                      case 7:
                        return O ? [3, 9] : [4, this.getFlags()];
                      case 8:
                        return i.sent(), [3, 10];
                      case 9:
                        if (E) this._onChange(null, {
                          isFromServer: !1,
                          flagsChanged: r({}, this.flags),
                          traitsChanged: r({}, null === (x = this.evaluationContext.identity) || void 0 === x ? void 0 : x.traits)
                        }, this._loadedState(null, s.DEFAULT_FLAGS));else {
                          if (!this.flags) throw new Error(G);
                          this._onChange(null, {
                            isFromServer: !1,
                            flagsChanged: r({}, this.flags),
                            traitsChanged: r({}, null === (F = this.evaluationContext.identity) || void 0 === F ? void 0 : F.traits)
                          }, this._loadedState(null, s.DEFAULT_FLAGS));
                        }
                        i.label = 10;
                      case 10:
                        return [2];
                    }
                  });
                });
              }, W.label = 2;
            case 2:
              return W.trys.push([2, 7,, 8]), g.getItemSync ? (H = g.getItemSync(this.getStorageKey()), [3, 5]) : [3, 3];
            case 3:
              return [4, g.getItem(this.getStorageKey())];
            case 4:
              H = W.sent(), W.label = 5;
            case 5:
              return [4, J(null, H)];
            case 6:
            case 7:
              return W.sent(), [3, 8];
            case 8:
              return [3, 12];
            case 9:
              return O ? [3, 11] : [4, this.getFlags()];
            case 10:
              return W.sent(), [3, 12];
            case 11:
              if (E) this._onChange(null, {
                isFromServer: !1,
                flagsChanged: r({}, E),
                traitsChanged: r({}, null === (a = c.identity) || void 0 === a ? void 0 : a.traits)
              }, this._loadedState(null, s.DEFAULT_FLAGS));else if (this.flags && (M = null, 0 === Object.keys(this.flags).length && (M = G), this._onChange(null, {
                isFromServer: !1,
                flagsChanged: r({}, this.flags),
                traitsChanged: r({}, null === (o = c.identity) || void 0 === o ? void 0 : o.traits)
              }, this._loadedState(M, s.DEFAULT_FLAGS)), M)) throw new Error(M);
              W.label = 12;
            case 12:
              return [3, 14];
            case 13:
              throw V = W.sent(), this.log("Error during initialisation ", V), q = V instanceof Error ? V : new Error("".concat(V)), null === (u = this.onError) || void 0 === u || u.call(this, q), V;
            case 14:
              return [2];
          }
          var X;
        });
      });
    }, t.prototype.getAllFlags = function () {
      return this.flags;
    }, t.prototype.identify = function (t, e, n) {
      return this.evaluationContext.identity = {
        identifier: t,
        transient: n,
        traits: this.evaluationContext.identity && this.evaluationContext.identity.identifier == t ? this.evaluationContext.identity.traits : {}
      }, this.evaluationContext.identity.identifier = t, this.log("Identify: " + this.evaluationContext.identity.identifier), e && (this.evaluationContext.identity.traits = Object.fromEntries(Object.entries(e).map(function (t) {
        var e = t[0],
          n = t[1];
        return [e, c(n) ? n : {
          value: n
        }];
      }))), this.initialised ? this.getFlags() : Promise.resolve();
    }, t.prototype.getState = function () {
      return {
        api: this.api,
        flags: this.flags,
        ts: this.ts,
        evaluationContext: this.evaluationContext,
        evaluationEvent: this.evaluationEvent
      };
    }, t.prototype.setState = function (t) {
      t && (this.initialised = !0, this.api = t.api || this.api || p, this.flags = t.flags || this.flags, this.evaluationContext = t.evaluationContext || this.evaluationContext, this.evaluationEvent = t.evaluationEvent || this.evaluationEvent, this.log("setState called", this));
    }, t.prototype.logout = function () {
      return this.evaluationContext.identity = null, this.initialised ? this.getFlags() : Promise.resolve();
    }, t.prototype.startListening = function (t) {
      void 0 === t && (t = 1e3), this.getFlagInterval && clearInterval(this.getFlagInterval), this.getFlagInterval = setInterval(this.getFlags, t);
    }, t.prototype.stopListening = function () {
      this.getFlagInterval && (clearInterval(this.getFlagInterval), this.getFlagInterval = null);
    }, t.prototype._loadedState = function (t, e, n) {
      return void 0 === t && (t = null), void 0 === n && (n = !1), {
        error: t,
        isFetching: n,
        isLoading: !1,
        source: e
      };
    }, t.prototype.log = function () {
      for (var t = [], e = 0; e < arguments.length; e++) t[e] = arguments[e];
      this.enableLogs && console.log.apply(this, a(["FLAGSMITH:", new Date().valueOf() - (this.timer || 0), "ms"], t, !0));
    }, t.prototype.updateStorage = function () {
      if (this.cacheFlags) {
        this.ts = new Date().valueOf();
        var t = JSON.stringify(this.getState());
        this.log("Setting storage", t), g.setItem(this.getStorageKey(), t);
      }
    }, t.prototype.updateEventStorage = function () {
      if (this.enableAnalytics) {
        var t = JSON.stringify(this.getState().evaluationEvent);
        g.setItem(f, t).catch(function (t) {
          return console.error("Flagsmith: Error setting item in async storage", t);
        });
      }
    }, t.prototype.setLoadingState = function (t) {
      var n;
      o(t, this.loadingState) || (this.loadingState = e({}, t), this.log("Loading state changed", t), null === (n = this._triggerLoadingState) || void 0 === n || n.call(this));
    }, t.prototype.setupRealtime = function (t, e) {
      var n = this,
        i = t + "sse/environments/" + e + "/stream";
      d ? this.eventSource || (this.log("Creating event source with url " + i), this.eventSource = new d(i), this.eventSource.addEventListener("environment_updated", function (t) {
        var e;
        try {
          e = JSON.parse(t.data).updated_at;
        } catch (t) {
          n.log("Could not parse sse event", t);
        }
        e ? !n.timestamp || e > n.timestamp ? n.isLoading ? n.log("updated_at is new, but flags are loading", t.data, n.timestamp) : (n.log("updated_at is new, fetching flags", t.data, n.timestamp), n.getFlags()) : n.log("updated_at is outdated, skipping get flags", t.data, n.timestamp) : n.log("No updated_at received, fetching flags", t);
      })) : this.log("Error, EventSource is undefined");
    }, t;
  }();
function m(t) {
  var e = t.fetch,
    n = t.AsyncStorage,
    i = t.eventSource;
  return new y({
    fetch: e,
    AsyncStorage: n,
    eventSource: i
  });
}
var S,
  C = (S = function (t, e) {
    return S = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (t, e) {
      t.__proto__ = e;
    } || function (t, e) {
      for (var n in e) Object.prototype.hasOwnProperty.call(e, n) && (t[n] = e[n]);
    }, S(t, e);
  }, function (t, e) {
    if ("function" != typeof e && null !== e) throw new TypeError("Class extends value " + String(e) + " is not a constructor or null");
    function n() {
      this.constructor = t;
    }
    S(t, e), t.prototype = null === e ? Object.create(e) : (n.prototype = e.prototype, new n());
  }),
  _ = function (t) {
    var e = "function" == typeof Symbol && Symbol.iterator,
      n = e && t[e],
      i = 0;
    if (n) return n.call(t);
    if (t && "number" == typeof t.length) return {
      next: function () {
        return t && i >= t.length && (t = void 0), {
          value: t && t[i++],
          done: !t
        };
      }
    };
    throw new TypeError(e ? "Object is not iterable." : "Symbol.iterator is not defined.");
  },
  b = function (t, e) {
    var n = "function" == typeof Symbol && t[Symbol.iterator];
    if (!n) return t;
    var i,
      a,
      o = n.call(t),
      r = [];
    try {
      for (; (void 0 === e || e-- > 0) && !(i = o.next()).done;) r.push(i.value);
    } catch (t) {
      a = {
        error: t
      };
    } finally {
      try {
        i && !i.done && (n = o.return) && n.call(o);
      } finally {
        if (a) throw a.error;
      }
    }
    return r;
  },
  E = function (t, e, n) {
    if (n || 2 === arguments.length) for (var i, a = 0, o = e.length; a < o; a++) !i && a in e || (i || (i = Array.prototype.slice.call(e, 0, a)), i[a] = e[a]);
    return t.concat(i || Array.prototype.slice.call(e));
  },
  x = function (t) {
    function e() {
      return t.call(this, "EventSource not available.\nConsider loading an EventSource polyfill and making it available globally as EventSource, or passing one in as eventSourceClass to the ReconnectingEventSource constructor.") || this;
    }
    return C(e, t), e;
  }(Error),
  O = function () {
    function t(t, e) {
      var n = this;
      if (this.CONNECTING = 0, this.OPEN = 1, this.CLOSED = 2, this._configuration = null != e ? Object.assign({}, e) : void 0, this.withCredentials = !1, this._eventSource = null, this._lastEventId = null, this._timer = null, this._listeners = {
        open: [],
        error: [],
        message: []
      }, this.url = t.toString(), this.readyState = this.CONNECTING, this.max_retry_time = 3e3, this.eventSourceClass = globalThis.FlagsmithEventSource, null != this._configuration && (this._configuration.lastEventId && (this._lastEventId = this._configuration.lastEventId, delete this._configuration.lastEventId), this._configuration.max_retry_time && (this.max_retry_time = this._configuration.max_retry_time, delete this._configuration.max_retry_time), this._configuration.eventSourceClass && (this.eventSourceClass = this._configuration.eventSourceClass, delete this._configuration.eventSourceClass)), null == this.eventSourceClass || "function" != typeof this.eventSourceClass) throw new x();
      this._onevent_wrapped = function (t) {
        n._onevent(t);
      }, this._start();
    }
    return t.prototype.dispatchEvent = function (t) {
      throw new Error("Method not implemented.");
    }, t.prototype._start = function () {
      var t,
        e,
        n = this,
        i = this.url;
      this._lastEventId && (-1 === i.indexOf("?") ? i += "?" : i += "&", i += "lastEventId=" + encodeURIComponent(this._lastEventId)), this._eventSource = new this.eventSourceClass(i, this._configuration), this._eventSource.onopen = function (t) {
        n._onopen(t);
      }, this._eventSource.onerror = function (t) {
        n._onerror(t);
      }, this._eventSource.onmessage = function (t) {
        n.onmessage(t);
      };
      try {
        for (var a = _(Object.keys(this._listeners)), o = a.next(); !o.done; o = a.next()) {
          var r = o.value;
          this._eventSource.addEventListener(r, this._onevent_wrapped);
        }
      } catch (e) {
        t = {
          error: e
        };
      } finally {
        try {
          o && !o.done && (e = a.return) && e.call(a);
        } finally {
          if (t) throw t.error;
        }
      }
    }, t.prototype._onopen = function (t) {
      0 === this.readyState && (this.readyState = 1, this.onopen(t));
    }, t.prototype._onerror = function (t) {
      var e = this;
      if (1 === this.readyState && (this.readyState = 0, this.onerror(t)), this._eventSource) {
        this._eventSource.close(), this._eventSource = null;
        var n = Math.round(this.max_retry_time * Math.random());
        this._timer = setTimeout(function () {
          return e._start();
        }, n);
      }
    }, t.prototype._onevent = function (t) {
      var e, n;
      t && t.lastEventId && (this._lastEventId = t.lastEventId);
      var i = this._listeners[t.type];
      if (null != i) try {
        for (var a = _(E([], b(i), !1)), o = a.next(); !o.done; o = a.next()) {
          o.value.call(this, t);
        }
      } catch (t) {
        e = {
          error: t
        };
      } finally {
        try {
          o && !o.done && (n = a.return) && n.call(a);
        } finally {
          if (e) throw e.error;
        }
      }
      "message" === t.type && this.onmessage(t);
    }, t.prototype.onopen = function (t) {}, t.prototype.onerror = function (t) {}, t.prototype.onmessage = function (t) {}, t.prototype.close = function () {
      this._timer && (clearTimeout(this._timer), this._timer = null), this._eventSource && (this._eventSource.close(), this._eventSource = null), this.readyState = 2;
    }, t.prototype.addEventListener = function (t, e, n) {
      null == this._listeners[t] && (this._listeners[t] = [], null != this._eventSource && this._eventSource.addEventListener(t, this._onevent_wrapped));
      var i = this._listeners[t];
      i.includes(e) || (this._listeners[t] = E(E([], b(i), !1), [e], !1));
    }, t.prototype.removeEventListener = function (t, e, n) {
      var i = this._listeners[t];
      this._listeners[t] = i.filter(function (t) {
        return t !== e;
      });
    }, t;
  }();
globalThis.FlagsmithEventSource = "undefined" != typeof EventSource ? EventSource : null;
var F = function (t, e) {
    return e = e || {}, new Promise(function (n, i) {
      var a = new XMLHttpRequest(),
        o = [],
        r = [],
        s = {},
        l = function () {
          return {
            ok: 2 == (a.status / 100 | 0),
            statusText: a.statusText,
            status: a.status,
            url: a.responseURL,
            text: function () {
              return Promise.resolve(a.responseText);
            },
            json: function () {
              return Promise.resolve(a.responseText).then(JSON.parse);
            },
            blob: function () {
              return Promise.resolve(new Blob([a.response]));
            },
            clone: l,
            headers: {
              keys: function () {
                return o;
              },
              entries: function () {
                return r;
              },
              get: function (t) {
                return s[t.toLowerCase()];
              },
              has: function (t) {
                return t.toLowerCase() in s;
              }
            }
          };
        };
      for (var u in a.open(e.method || "get", t, !0), a.onload = function () {
        a.getAllResponseHeaders().replace(/^(.*?):[^\S\n]*([\s\S]*?)$/gm, function (t, e, n) {
          o.push(e = e.toLowerCase()), r.push([e, n]), s[e] = s[e] ? s[e] + "," + n : n;
        }), n(l());
      }, a.onerror = i, a.withCredentials = "include" == e.credentials, e.headers) a.setRequestHeader(u, e.headers[u]);
      a.send(e.body || null);
    });
  },
  w = m({
    AsyncStorage: t,
    fetch: F,
    eventSource: O
  });
"undefined" != typeof window && (window.flagsmith = w);
var L = function () {
  return m({
    AsyncStorage: t,
    fetch: F,
    eventSource: O
  });
};
export { L as createFlagsmithInstance, w as default };
