<div class="cb-login-content">
    <img
        *ngIf="isAdmin()"
        [src]="'assets/images/Checkbox.svg' | assetspipe"
        alt="Checkbox"
        class="login-log-image"
    />
    <mat-card appearance="outlined">
        <mat-card-title>
            {{ 'RESET-PASSWORD.CHANGE-PASSWORD' | translate }}
        </mat-card-title>
        <mat-card-content>
            <form [formGroup]="form" (ngSubmit)="onSubmit()">
                <ng-container *ngIf="!isServerVersion && !!this.signInData?.account">
                    <mat-form-field class="cb-50-width-input cb-left">
                        <mat-label>{{ 'RESET-PASSWORD.ACCOUNT-NAME' | translate }}</mat-label>
                        <input
                            matInput
                            formControlName="account"
                        />
                    </mat-form-field>
                    <mat-form-field class="cb-50-width-input cb-right">
                        <label>.{{ getEnvironmentDomain() }}</label>
                        <input matInput [hidden]="true" />
                    </mat-form-field>
                </ng-container>
                <mat-form-field class="cb-full-width-input">
                    <mat-label>{{ 'RESET-PASSWORD.USERNAME' | translate }}</mat-label>
                    <input
                        matInput
                        formControlName="id"
                    />
                </mat-form-field>
                <mat-form-field class="cb-full-width-input">
                    <mat-label>{{ 'RESET-PASSWORD.NEW-PASSWORD' | translate }}</mat-label>
                    <input
                        matInput
                        type="password"
                        formControlName="newPassword"
                    />
                    <mat-error *ngIf="form.controls['newPassword'].hasError('required'); else is_old_password">
                        {{ 'FORMS.PASSWORD-VALIDATION-REQUIRED' | translate }}
                    </mat-error>
                    <ng-template #is_old_password>
                        <mat-error *ngIf="form.controls['newPassword'].hasError('isOldPassword'); else password_validation_error">
                            {{ 'FORMS.PASSWORD-VALIDATION-IS_OLD' | translate }}
                        </mat-error>
                    </ng-template>
                    <ng-template #password_validation_error>
                        <mat-error>
                            {{ passwordValidationError$() | async }}
                        </mat-error>
                    </ng-template>
                </mat-form-field>
                <mat-form-field class="cb-full-width-input confirm-password-field">
                    <mat-label>{{ 'RESET-PASSWORD.CONFIRM-PASSWORD' | translate }}</mat-label>
                    <input
                        matInput
                        type="password"
                        formControlName="confirmPassword"
                    />
                    <mat-error>
                        {{ 'FORMS.PASSWORD-MISMATCH-CONFIRMATION' | translate }}
                    </mat-error>
                </mat-form-field>
                <mat-card-actions >
                    <div class="form-actions-block">
                        <button
                            mat-button
                            type="button"
                            class="btn-cancel"
                            [routerLink]="[loginUrl]"
                        >
                            {{ 'FORMS.BACK-TO-LOGIN' | translate }}
                        </button>
                        <button
                            mat-raised-button
                            class="btn-submit"
                            type="submit"
                        >
                            {{ 'RESET-PASSWORD.CHANGE-PASSWORD' | translate }}
                        </button>
                    </div>
                </mat-card-actions>
            </form>
        </mat-card-content>
    </mat-card>
</div>
