(function (f) {
  if (typeof exports === "object" && typeof module !== "undefined") {
    module.exports = f();
  } else if (typeof define === "function" && define.amd) {
    define([], f);
  } else {
    var g;
    if (typeof window !== "undefined") {
      g = window;
    } else if (typeof global !== "undefined") {
      g = global;
    } else if (typeof self !== "undefined") {
      g = self;
    } else {
      g = this;
    }
    g.typescriptStringOperationsNg4 = f();
  }
})(function () {
  var define, module, exports;
  return function e(t, n, r) {
    function s(o, u) {
      if (!n[o]) {
        if (!t[o]) {
          var a = typeof require == "function" && require;
          if (!u && a) return a(o, !0);
          if (i) return i(o, !0);
          var f = new Error("Cannot find module '" + o + "'");
          throw f.code = "MODULE_NOT_FOUND", f;
        }
        var l = n[o] = {
          exports: {}
        };
        t[o][0].call(l.exports, function (e) {
          var n = t[o][1][e];
          return s(n ? n : e);
        }, l, l.exports, e, t, n, r);
      }
      return n[o].exports;
    }
    var i = typeof require == "function" && require;
    for (var o = 0; o < r.length; o++) s(r[o]);
    return s;
  }({
    1: [function (require, module, exports) {
      module.exports = function (t) {
        function r(n) {
          if (e[n]) return e[n].exports;
          var a = e[n] = {
            i: n,
            l: !1,
            exports: {}
          };
          return t[n].call(a.exports, a, a.exports, r), a.l = !0, a.exports;
        }
        var e = {};
        return r.m = t, r.c = e, r.d = function (t, e, n) {
          r.o(t, e) || Object.defineProperty(t, e, {
            configurable: !1,
            enumerable: !0,
            get: n
          });
        }, r.n = function (t) {
          var e = t && t.__esModule ? function () {
            return t.default;
          } : function () {
            return t;
          };
          return r.d(e, "a", e), e;
        }, r.o = function (t, r) {
          return Object.prototype.hasOwnProperty.call(t, r);
        }, r.p = "", r(r.s = 0);
      }([function (t, r, e) {
        "use strict";

        Object.defineProperty(r, "__esModule", {
          value: !0
        });
        var n = e(1);
        r.String = n.String;
        var a = e(2);
        r.StringBuilder = a.StringBuilder;
      }, function (t, r, e) {
        "use strict";

        Object.defineProperty(r, "__esModule", {
          value: !0
        });
        var n = function () {
          function t() {}
          return t.IsNullOrWhiteSpace = function (t) {
            try {
              return null === t || "undefined" === t || t.toString().replace(/\s/g, "").length < 1;
            } catch (t) {
              return !1;
            }
          }, t.Join = function (r) {
            for (var e = [], n = 1; n < arguments.length; n++) e[n - 1] = arguments[n];
            try {
              var a = e[0];
              if (Array.isArray(a) || a instanceof Array) {
                for (var i = t.Empty, o = 0; o < a.length; o++) {
                  var l = a[o];
                  o < a.length - 1 ? i += l + r : i += l;
                }
                return i;
              }
              if ("object" == typeof a) {
                var u = t.Empty,
                  g = a;
                return Object.keys(a).forEach(function (t) {
                  u += g[t] + r;
                }), u = u.slice(0, u.length - r.length);
              }
              var c = e;
              return t.join.apply(t, [r].concat(c));
            } catch (r) {
              return t.Empty;
            }
          }, t.Format = function (r) {
            for (var e = [], n = 1; n < arguments.length; n++) e[n - 1] = arguments[n];
            try {
              return r.replace(/{(\d+(:\w*)?)}/g, function (r, n) {
                var a = r.split(":");
                a.length > 1 && (n = n[0], r = a[1].replace("}", ""));
                var i = t.parsePattern(r, e[n]);
                return void 0 !== i && null != i ? i : t.Empty;
              });
            } catch (r) {
              return t.Empty;
            }
          }, t.parsePattern = function (r, e) {
            if (null === e || void 0 === e) return e;
            switch (r) {
              case "L":
                return e = e.toLowerCase();
              case "U":
                return e = e.toUpperCase();
              case "d":
                if ("string" == typeof e) return t.getDisplayDateFromString(e);
                if (e instanceof Date) return t.Format("{0:00}.{1:00}.{2:0000}", e.getDate(), e.getMonth(), e.getFullYear());
                break;
              case "s":
                if ("string" == typeof e) return t.getSortableDateFromString(e);
                if (e instanceof Date) return t.Format("{0:0000}-{1:00}-{2:00}", e.getFullYear(), e.getMonth(), e.getDate());
                break;
              case "n":
                var n = e.replace(/,/g, ".");
                if (isNaN(parseFloat(n)) || n.length <= 3) break;
                var a = n.split(/[^0-9]+/g),
                  i = a;
                a.length > 1 && (i = [t.join.apply(t, [""].concat(a.splice(0, a.length - 1))), a[a.length - 1]]);
                var o = i[0],
                  l = o.length % 3,
                  u = l > 0 ? o.substring(0, l) : t.Empty,
                  g = o.substring(l).match(/.{3}/g);
                return u = u + "." + t.Join(".", g), e = u + (i.length > 1 ? "," + i[1] : "");
            }
            return "number" == typeof e ? t.formatNumber(e, r) : e;
          }, t.getDisplayDateFromString = function (t) {
            var r;
            if (r = t.split("-"), r.length <= 1) return t;
            var e = r[r.length - 1],
              n = r[r.length - 2],
              a = r[r.length - 3];
            return e = e.split("T")[0], (e = e.split(" ")[0]) + "." + n + "." + a;
          }, t.getSortableDateFromString = function (t) {
            var r = t.replace(",", "").split(".");
            if (r.length <= 1) return t;
            var e = r[r.length - 1].split(" "),
              n = r[0];
            e.length > 1 && (n = e[e.length - 1]);
            var a = r[r.length - 1].split(" ")[0],
              i = r[r.length - 2],
              o = r[r.length - 3],
              l = a + "-" + i + "-" + o;
            return n.length > 1 ? l += "T" + n : l += "T00:00:00", l;
          }, t.formatNumber = function (t, r) {
            var e = r.length,
              n = t.toString();
            if (e <= n.length) return n;
            var a = e - n.length;
            return a += 1, new Array(a).join("0") + n;
          }, t.join = function (r) {
            for (var e = [], n = 1; n < arguments.length; n++) e[n - 1] = arguments[n];
            for (var a = t.Empty, i = 0; i < e.length; i++) if (!("string" == typeof e[i] && t.IsNullOrWhiteSpace(e[i]) || "number" != typeof e[i] && "string" != typeof e[i])) {
              var o = "" + e[i];
              a += o;
              for (var l = i + 1; l < e.length; l++) if (!t.IsNullOrWhiteSpace(e[l])) {
                a += r, i = l - 1;
                break;
              }
            }
            return a;
          }, t.Empty = "", t;
        }();
        r.String = n;
      }, function (t, r, e) {
        "use strict";

        Object.defineProperty(r, "__esModule", {
          value: !0
        });
        var n = e(3),
          a = function () {
            function t(t) {
              void 0 === t && (t = n.String.Empty), this.Values = [], this.Values = new Array(t);
            }
            return t.prototype.ToString = function () {
              return this.Values.join("");
            }, t.prototype.Append = function (t) {
              this.Values.push(t);
            }, t.prototype.AppendFormat = function (t) {
              for (var r = [], e = 1; e < arguments.length; e++) r[e - 1] = arguments[e];
              this.Values.push(n.String.Format.apply(n.String, [t].concat(r)));
            }, t.prototype.Clear = function () {
              this.Values = [];
            }, t;
          }();
        r.StringBuilder = a;
      }, function (t, r, e) {
        "use strict";

        Object.defineProperty(r, "__esModule", {
          value: !0
        });
        var n = function () {
          function t() {}
          return t.IsNullOrWhiteSpace = function (t) {
            try {
              return null === t || "undefined" === t || t.toString().replace(/\s/g, "").length < 1;
            } catch (t) {
              return !1;
            }
          }, t.Join = function (r) {
            for (var e = [], n = 1; n < arguments.length; n++) e[n - 1] = arguments[n];
            try {
              var a = e[0];
              if (Array.isArray(a) || a instanceof Array) {
                for (var i = t.Empty, o = 0; o < a.length; o++) {
                  var l = a[o];
                  o < a.length - 1 ? i += l + r : i += l;
                }
                return i;
              }
              if ("object" == typeof a) {
                var u = t.Empty,
                  g = a;
                return Object.keys(a).forEach(function (t) {
                  u += g[t] + r;
                }), u = u.slice(0, u.length - r.length);
              }
              var c = e;
              return t.join.apply(t, [r].concat(c));
            } catch (r) {
              return t.Empty;
            }
          }, t.Format = function (r) {
            for (var e = [], n = 1; n < arguments.length; n++) e[n - 1] = arguments[n];
            try {
              return r.replace(/{(\d+(:\w*)?)}/g, function (r, n) {
                var a = r.split(":");
                a.length > 1 && (n = n[0], r = a[1].replace("}", ""));
                var i = t.parsePattern(r, e[n]);
                return void 0 !== i && null != i ? i : t.Empty;
              });
            } catch (r) {
              return t.Empty;
            }
          }, t.parsePattern = function (r, e) {
            if (null === e || void 0 === e) return e;
            switch (r) {
              case "L":
                return e = e.toLowerCase();
              case "U":
                return e = e.toUpperCase();
              case "d":
                if ("string" == typeof e) return t.getDisplayDateFromString(e);
                if (e instanceof Date) return t.Format("{0:00}.{1:00}.{2:0000}", e.getDate(), e.getMonth(), e.getFullYear());
                break;
              case "s":
                if ("string" == typeof e) return t.getSortableDateFromString(e);
                if (e instanceof Date) return t.Format("{0:0000}-{1:00}-{2:00}", e.getFullYear(), e.getMonth(), e.getDate());
                break;
              case "n":
                var n = e.replace(/,/g, ".");
                if (isNaN(parseFloat(n)) || n.length <= 3) break;
                var a = n.split(/[^0-9]+/g),
                  i = a;
                a.length > 1 && (i = [t.join.apply(t, [""].concat(a.splice(0, a.length - 1))), a[a.length - 1]]);
                var o = i[0],
                  l = o.length % 3,
                  u = l > 0 ? o.substring(0, l) : t.Empty,
                  g = o.substring(l).match(/.{3}/g);
                return u = u + "." + t.Join(".", g), e = u + (i.length > 1 ? "," + i[1] : "");
            }
            return "number" == typeof e ? t.formatNumber(e, r) : e;
          }, t.getDisplayDateFromString = function (t) {
            var r;
            if (r = t.split("-"), r.length <= 1) return t;
            var e = r[r.length - 1],
              n = r[r.length - 2],
              a = r[r.length - 3];
            return e = e.split("T")[0], (e = e.split(" ")[0]) + "." + n + "." + a;
          }, t.getSortableDateFromString = function (t) {
            var r = t.replace(",", "").split(".");
            if (r.length <= 1) return t;
            var e = r[r.length - 1].split(" "),
              n = r[0];
            e.length > 1 && (n = e[e.length - 1]);
            var a = r[r.length - 1].split(" ")[0],
              i = r[r.length - 2],
              o = r[r.length - 3],
              l = a + "-" + i + "-" + o;
            return n.length > 1 ? l += "T" + n : l += "T00:00:00", l;
          }, t.formatNumber = function (t, r) {
            var e = r.length,
              n = t.toString();
            if (e <= n.length) return n;
            var a = e - n.length;
            return a += 1, new Array(a).join("0") + n;
          }, t.join = function (r) {
            for (var e = [], n = 1; n < arguments.length; n++) e[n - 1] = arguments[n];
            for (var a = t.Empty, i = 0; i < e.length; i++) if (!("string" == typeof e[i] && t.IsNullOrWhiteSpace(e[i]) || "number" != typeof e[i] && "string" != typeof e[i])) {
              var o = "" + e[i];
              a += o;
              for (var l = i + 1; l < e.length; l++) if (!t.IsNullOrWhiteSpace(e[l])) {
                a += r, i = l - 1;
                break;
              }
            }
            return a;
          }, t.Empty = "", t;
        }();
        r.String = n;
      }]);
    }, {}]
  }, {}, [1])(1);
});