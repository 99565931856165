<button
    [matTooltip]="tooltip"
    mat-icon-button
    (menuClosed)="appMenuClose()"
    (menuOpened)="appMenuOpen()"
    [ngClass]="['is-menu-button', isMenuOpened]"
    (click)="onClick($event)"
    class="icon-with-caret-button"
    [matMenuTriggerFor]="menuTrigger"
>
        <span
            class="d-flex align-items-center cb-icon-caret-container"
            *ngIf="menuTrigger"
        >
        <cb-svg-icon *ngIf="!iconRegistered" class="icon" [name]="name"></cb-svg-icon>
        <mat-icon *ngIf="iconRegistered" class="icon" [svgIcon]="name"></mat-icon>
        <ng-content></ng-content>

        <span class="caret-icon">
            <i class="fas fa-caret-down icon-down"></i>
            <i class="fas fa-caret-up icon-up"></i>
        </span>
    </span>

</button>
