import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { StorageProvider } from './storage.provider';
import { ToasterService } from '../services';
import { ApplicationSettingsProvider } from '../../app-admin/settings/application-settings/application-settings.provider';
import { Router } from '@angular/router';
import { Identity, JWTSignInEffectData } from '../models';
import { forkJoin, Observable, of, of as observableOf, Subject, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { catchError, first, map, mergeMap } from 'rxjs/operators';
import { ResourcesConsts } from '../consts/resources.const';
import { IdentityProvider } from './identity.provider';
import { IdentityStore } from '../store/identity.store';
import * as _ from 'lodash';
import { NgxPermissionsService } from 'ngx-permissions';
import { ApplicationLanguageService } from '../services/application-language.service';
import { JWTSignInType } from '../models/signInWithJWT.model';

@Injectable()
export class JWTProvider {
    constructor(
        private $http: HttpClient,
        private storageProvider: StorageProvider,
        private toasterService: ToasterService,
        private systemSettingsProvider: ApplicationSettingsProvider,
        private router: Router,
        private identityProvider: IdentityProvider,
        private identityStore: IdentityStore,
        private permService: NgxPermissionsService,
        private applicationLanguageService: ApplicationLanguageService,
    ) {
    }
    jwtLoginInProgress = new Subject<boolean>();
    // Todo migration: Below line
    // signInWithJWT({ token, jwtType, destinationURL }: JWTSignInEffectData): Observable<Identity> {
    signInWithJWT({ token, jwtType, destinationURL }: JWTSignInEffectData): any {
        let userName;
        return this.decodeJWTToken(token).pipe(
            mergeMap((data) => {
                userName = data.userName;
                return this.handleAccountName(data.accountName);
            }),
            mergeMap(() => {
                return this.handleInternalAccess(jwtType, userName);
            }),
            mergeMap((result: boolean) => {
                if (result) {
                    return this.getIdentityWithJWT(token, jwtType);
                } else {
                    return observableOf(null);
                }
            }),
            map(identity => {
                this.setRedirectUrl(destinationURL);
                return identity;
            }),
            catchError((err: any) => this.handleJWTError(err))
        );
    }

    decodeJWTToken(jwt: string) {
        const jwtKeys = ['uid', 'jti', 'time_stamp'];
        if (environment.isMultiTenant) {
            jwtKeys.push('tid');
        }
        try {
            const payload = jwt.split('.')[1];
            const parsedPayload = JSON.parse(atob(payload));
            if (_.every(jwtKeys, key => parsedPayload[key])) {
                return of({
                    accountName: parsedPayload.tid,
                    userName: parsedPayload.uid
                });
            } else {
                return throwError({ message: 'Invalid JWT token' });
            }
        } catch (e) {
            return throwError({ message: 'Invalid JWT token' });
        }
    }

    handleAccountName(accountName: string): Observable<boolean> {
        return this.storageProvider.saveAccount(accountName);
    }

    handleInternalAccess(jwtType: JWTSignInType, username: string): Observable<boolean> {
        if (jwtType === JWTSignInType.MasterSite) {
            return of(true);
        } else {
            return this.identityProvider.checkConcurrentUsers({ username });
        }
    }

    getIdentityWithJWT(token: string, jwtType: JWTSignInType) {
        const authUrl: string = ResourcesConsts.SIGNIN;
        const clientId = jwtType === JWTSignInType.MasterSite
            ? ''
            : (environment.isAdminSite ? 'AdminWebApp' : 'TakeSurveyWebApp');
        const params = new HttpParams()
            .set('grant_type', this.getGrantType(jwtType))
            .set('token', token)
            .set('device_id', this.identityProvider.getDeviceId())
            .set('client_id', clientId);
        return this.$http.post(authUrl, params).pipe(
            mergeMap((identity: Identity) => this.identityProvider.saveIdentity(identity))
        );
    }

    private getGrantType(jwtType: JWTSignInType) {
        switch(jwtType) {
            case JWTSignInType.MasterSite:
                return 'jwt_internal_access';
            case JWTSignInType.SingleSignOn:
                return 'jwt'; 
            case JWTSignInType.AdminAppRedirect:
                return 'jwt_admin_redirect';
        }
    }

    setRedirectUrl(destinationURL) {
        if (destinationURL) {
            this.identityStore.setRedirectUri(
                destinationURL.replace(/^.*\/\/[^\/]+/, '')
            );
        }
    }

    handleJWTError(err) {
        console.log('err', err);
        if (!err.url) { // exclude http-related errors, they will display by their own
            this.toasterService.showError(err, true);
        }
        return this.storageProvider.getAccount().pipe(
            mergeMap(acc => {
                this.jwtLoginInProgress.next(false);
                if (acc) {
                    return this.redirectToJWTAppSettingsURL().pipe(
                        catchError(error => {
                            return this.redirectToJWTDefaultURL(err);
                        })
                    );
                } else {
                    return this.redirectToJWTDefaultURL(err);
                }
            })
        );
    }

    redirectToJWTAppSettingsURL() {
        return this.systemSettingsProvider.getUserApplicationSettings().pipe(
            first(),
            map(appSettings => {
                const enableJWT = appSettings.singleSignOn.enable_jwt;
                const redirectUrl = appSettings.singleSignOn.jwt_return_url;
                if (enableJWT) {
                    if (redirectUrl.indexOf('http') > -1) {
                        return window.location.replace(redirectUrl);
                    } else {
                        if (redirectUrl) {
                            return this.router.navigate([redirectUrl]);
                        } else {
                            throw throwError({ message: 'Redirect URL is not specified' });
                        }
                    }
                }
            })
        );
    }

    redirectToJWTDefaultURL(err) {
        const defaultUrl = '/login';
        return this.router.navigate([defaultUrl]);
    }

    initAppWithJWT(data: JWTSignInEffectData) {
        return this.identityProvider.logOut().pipe(
            mergeMap(() => {
                return this.signInWithJWT(data);
            }),
            mergeMap((identity: Identity) => {
                const rls =
                    // if identity === null error happens there. It is being handled by catchError handler below.
                    typeof identity.roles === 'string'
                        ? identity.roles.split(',')
                        : identity.roles;
                this.permService.addPermission(rls);
                this.identityStore.doStoreIdentity(identity);
                return forkJoin([
                    of(identity),
                    this.applicationLanguageService.initLanguageSettings()
                ]);
            })
        );
    }
}
