import { ValidationErrors, ValidatorFn, AbstractControl, UntypedFormGroup } from '@angular/forms';

export class ControlsValuesValidator {
    static isNotMatched(
        controlName1: string,
        controlName2: string
    ): ValidatorFn {
        return (group: UntypedFormGroup): ValidationErrors => {
            const value1 = group?.controls[controlName1]?.value;
            const value2 = group?.controls[controlName2]?.value;
            const error = (!!value1 && !!value2 && value1 !== value2)
                ? { isNotMatched: true }
                : null;
            group?.controls[controlName2]?.setErrors(error);
            return error;
        };
    }
}
