import { EditorModule } from '@tinymce/tinymce-angular';
import { CustomMaterialModule } from './material-components.module';
import { NgModule } from '@angular/core';
import { SpinnerComponent } from '../components/spinner/spinner.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TinyRichEditorComponent } from '../components/rich-editor/tiny-rich-editor.component';
import { RouterModule } from '@angular/router';
import { RecaptchaModule } from 'ng-recaptcha-2';
// tslint:disable-next-line:max-line-length
import { PureItemOptionsOnly } from '../pipes/item-options.pipe';
import { SvgComponent } from '../components/svg/svg.component';
import { SvgDefinitionsComponent } from '../components/svg-definitions/svg-definitions.component';
import { sharedServices } from '../services/index';

// tslint:disable-next-line:max-line-length
import { LiveFilterTextComponent } from '../../app-admin/dashboards/live-filter-text/live-filter-text.component';
import { IconWithCaretComponent } from '../components/icon-with-caret/icon-with-caret.component';
import { DateTimePickerComponent } from '../components/date-time-picker/date-time-picker.component';
import { ChipComponent } from '../components/chip/chip.component';
import { ButtonComponent } from '../components/button/button.component';
//Material Components

import { TakeSurveyPipingService } from '../../infrastructure/services/take-survey-piping.service';
import { ContentFileProvider } from '../../app-admin/providers/content-file.provider';
import { RespondentFileUploadProvider } from '../../app-take-survey/providers/respondent-file-upload.provider';
import { EscapeHtmlPipe } from '../pipes/keep-html.pipe';
import { MapKeysPipe } from '../pipes/map-keys.pipe';
import { SortPipe } from '../pipes/order-by.pipe';
import { LazyTranslateLoaderModule } from '../../app-admin/entry-point/lazy-translate-loader.module';
import { ChangePasswordComponent } from '../components/change-password/change-password.component';
import { ChangePasswordPageComponent } from '../containers/change-password-page/change-password-page.component';

import { AssetsPipe } from '../pipes/assets.pipe';
import { CustomMatSnackBarComponent } from '../components/custom-mat-snack-bar/custom-mat-snack-bar.component';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
// tslint:disable-next-line:max-line-length
import { ClipboardModule } from 'ngx-clipboard';
import { SimpleConfirmationDialogComponent } from '../../app-admin/shared/simple-confirmation-dialog/simple-confirmation-dialog.component';
import { ConfirmationDialogComponent } from '../../app-admin/shared/confirmation-dialog/confirmation-dialog.component';
import { DialogsService } from '../../infrastructure/services/dialogs.service';
import { StyledHeaderComponent } from '../../app-take-survey/surveys/styled-header/styled-header.component';
import { TrimHtmlPipe } from '../pipes/extract-inner-text.pipe';
import { SecuritySettingsResolver } from '../services/security-settings.resolver';
import { sharedDirectives } from '../directives';
import { SecurePipe } from '../pipes/secure.pipe';
import { NotNullPipe } from '../pipes/not-null.pipe';
import { MainDialogComponent } from '../components/main-dialog/main-dialog.component';
import { ElipsisPipe } from '../pipes/elipsis.pipe';
import { SurveyOwnerShow } from '../components/survey-owner-show/survey-owner-show.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        EditorModule,
        RouterModule,
        RecaptchaModule,
        // translation
        LazyTranslateLoaderModule,
        // custom
        CustomMaterialModule,
        ClipboardModule,
    ],
    declarations: [
        // components
        SpinnerComponent,
        TinyRichEditorComponent,
        IconWithCaretComponent,
        DateTimePickerComponent,
        ChangePasswordComponent,
        ChangePasswordPageComponent,
        // pipes
        PureItemOptionsOnly,
        SvgComponent,
        SvgDefinitionsComponent,
        LiveFilterTextComponent,
        EscapeHtmlPipe,
        TrimHtmlPipe,
        MapKeysPipe,
        SortPipe,
        AssetsPipe,
        SecurePipe,
        NotNullPipe,
        CustomMatSnackBarComponent,
        SimpleConfirmationDialogComponent,
        ConfirmationDialogComponent,
        StyledHeaderComponent,
        ChipComponent,
        ButtonComponent,
        MainDialogComponent,
        ElipsisPipe,
        SurveyOwnerShow,
        ...sharedDirectives,
    ],
    exports: [
        SpinnerComponent,
        TinyRichEditorComponent,
        IconWithCaretComponent,
        DateTimePickerComponent,
        ChangePasswordComponent,
        ChangePasswordPageComponent,
        // pipes
        PureItemOptionsOnly,
        SvgComponent,
        SvgDefinitionsComponent,
        LiveFilterTextComponent,
        EscapeHtmlPipe,
        MapKeysPipe,
        SortPipe,
        AssetsPipe,
        SecurePipe,
        NotNullPipe,
        CustomMatSnackBarComponent,
        StyledHeaderComponent,
        TrimHtmlPipe,
        ChipComponent,
        ButtonComponent,
        MainDialogComponent,
        ElipsisPipe,
        SurveyOwnerShow,
        ...sharedDirectives,
    ],
    providers: [
        ...sharedServices,
        TakeSurveyPipingService,
        ContentFileProvider,
        RespondentFileUploadProvider,
        DialogsService,
        SecuritySettingsResolver,
        // {provide: NgxMatMomentAdapter, useClass: NgxMatMomentAdapter, deps: [NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS]},
        { provide: MAT_SNACK_BAR_DATA, useValue: {} },
        { provide: MatSnackBarRef, useValue: {} }
    ]
})
export class SharedModule {}
